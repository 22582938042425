import React from 'react';
import './Halloween.css'

const AnimatedSvgImage = ({image, cssAndAnimation, opacityParam}) => {

    return (

        <>      
            <img id="ghost" src={image} alt="Ghost" className={cssAndAnimation} style={{opacity:opacityParam}}/>   
            {/* <img id="ghost" src={image} alt="Ghost" className={cssAndAnimation} style={{opacity:opacityParam, border: '1px solid white'}}/>    */}
        </>
        
    )
}

export default AnimatedSvgImage