import React, { useEffect }  from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom';
// import { removeLocalCustomerEmail } from './../../../utils/localStorageUtils';

const CheckoutSuccess = ({ fetchUser}) => {   

    // useEffect(() => {
    //     // Adăugarea event snippet-ului Google Tag pentru conversii
    //     window.gtag('event', 'conversion', {
    //       'send_to': 'AW-742617938/KnUPCJ-ZgawDENLmjeIC'
    //     });
    //   }, []); // Se execută doar o dată la montarea componentei

      useEffect(() => {
        if (window.gtag) {
          window.gtag('event', 'conversion', {
            'send_to': 'AW-742617938/KnUPCJ-ZgawDENLmjeIC'
          });
        }
      }, []); // Se execută doar o dată la montarea componentei

    return (
        <Container style={{ marginTop: '3em' }}>
            <Row>
                <Col md={{ span: 6, offset: 3}}>
                    <div className='checkout'>
                    <h1>Bine ai venit la curs!</h1>
                    <p>Momentan procesăm comanda și în curând vei primi un email de confirmare.</p>
                    <div>
                        <Link to="/courses" onClick={ () => fetchUser() } style={{ marginTop: '20px', width: '100%' }} className='btn btn-dark'>
                            Înapoi la cursuri
                        </Link>
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default CheckoutSuccess;