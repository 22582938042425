import React from 'react';
import './Halloween.css'

const Player = ({image, movementType, flag}) => {

    return (

        <>      
        {
            flag
            ? 
            <img id="ghost" src={image} alt="Ghost" className={movementType}  width="100" height="67" style={{opacity:'0'}}/>   
            :
            <img id="ghost" src={image} alt="Ghost" className={movementType}  width="100" height="100" style={{opacity:'0'}}/> 
        }      
        </>
        
    )
}

export default Player