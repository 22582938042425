import React from 'react';

const PromoCourses = () => {



    return (
        <>
            <h3 className="text-center mb-4">În ianuarie, folosești codul <strong>MATE2025</strong> pentru 30% reducere la oricare dintre cursurile:</h3>

            <h5>Curs complet de matematica pentru Examenul de Bacalaureat</h5> 

            <h5>Bacalaureat 2024 - Totul despre Subiectul I</h5>

            <h5>Evaluare Națională 2022 – Geometrie in plan</h5> 

            <h5>Polinoame pentru toți</h5>  

            <h5>Totul despre Sisteme de ecuații liniare</h5> 

            <h5>Limite de funcții - Metoda ușoară</h5> 

            <h5>Curs de trigonometrie pentru clasa a IX-a</h5>

            <h5>Tabăra de mate - Algebră.08</h5> 
            
            <p className="text-center mt-4">📅 Începe-ți pregătirea acum și pășește cu dreptul în 2025! 🚀</p>

            <br/>
        </>
        )
    }
    
export default PromoCourses