import React from 'react';

import './Halloween.css'
import Player from './Player';
import AnimatedSvgImage from './AnimatedSvgImage';

// 31st of December - The New Year's Eve
import fireworks01 from './newYear/01-fireworks-svgrepo-com.svg'
import fireworks02 from './newYear/02-firework-svgrepo-com.svg'
import fireworks03 from './newYear/03-balloons-new-year-svgrepo-com.svg'
import fireworks04 from './newYear/04-balloons-new-year-svgrepo-com.svg'
import spinningStar from './newYear/star-fireworks-svgrepo-com.svg'

// 25th of December - Christmas
// import santa01 from './santa/BW_Santa-with-reindeer.svg'
// import shootingStar from './santa/shooting-star-star-svgrepo-com.svg'

// 21st of December - The Romanian Revolution
// import helicopter from './revolution/helicopter-silhouette-svgrepo-com.svg'
// import ak47 from './revolution/ak47-svgrepo-com.svg'
// import bullet from './revolution/bullet-point-svgrepo-com.svg'
// import radium from './revolution/Radium-224.svg'

// 31st of October - The Haloween 
// import ghostImage01 from './ghosts/ghost-svgrepo-com (1).svg';
// import ghostImage02 from './ghosts/ghost-svgrepo-com (2).svg'
// import ghostImage03 from './ghosts/ghost-svgrepo-com (3).svg'
// import ghostImage04 from './ghosts/ghost-svgrepo-com (4).svg'
// import ghostImage05 from './ghosts/ghost-svgrepo-com (5).svg'
// import ghostImage06 from './ghosts/ghost-svgrepo-com (6).svg'
// import ghostImage07 from './ghosts/ghost-svgrepo-com (7).svg'
// import ghostImage08 from './ghosts/ghost-svgrepo-com (8).svg'
// import ghostImage09 from './ghosts/ghost-svgrepo-com (9).svg'
// import ghostImage10 from './ghosts/ghost-svgrepo-com (10).svg'
// import pumpkinImage from './ghosts/pumpkin01.svg';

// 1st of December - The National Day
import drapel01 from './drapele/drapel_01.png'
import drapel02 from './drapele/drapel_02.png'
import drapel03 from './drapele/drapel_03.png'
import drapel04 from './drapele/drapel_05.png'
import drapel05 from './drapele/drapel_04.png'
import drapel06 from './drapele/drapel_06.png'
import drapel07 from './drapele/drapel_07.png'
import drapel08 from './drapele/drapel_08.png'
import drapel09 from './drapele/drapel_09.png'
import drapel10 from './drapele/drapel_10.png'
import drapel11 from './drapele/drapel_11.svg'
import coroana from './drapele/coroana.jpeg'

const Halloween = () => {
    // const ghostImages = Array.from({ length: 10 }, (_, index) => `/ghostImage${(index + 1).toString().padStart(2, '0')}.png`);
    // ghostImages.push(pumpkinImage)
    // const partyPics = ghostImages


    return (
        
        <div className="halloween-container">
        {/* 31st of December - New Year's Eve */}
        <>   
            {/* <AnimatedSvgImage image={fireworks01} cssAndAnimation={'rise-with-tangent-2'} opacityParam={'0'}/> */}
            {/* <AnimatedSvgImage image={fireworks01} cssAndAnimation={'falling-rocket'} opacityParam={'0'}/> */}
            {/* <AnimatedSvgImage image={fireworks02} cssAndAnimation={'rising-star-with-tangent-2'} opacityParam={'0'}/> */}
            {/* <AnimatedSvgImage image={fireworks03} cssAndAnimation={'risingBaloon01'} opacityParam={'0'}/> */}
            {/* <AnimatedSvgImage image={fireworks04} cssAndAnimation={'risingBaloon02'} opacityParam={'0'}/> */}
            {/* <Player image={spinningStar} movementType={'spinningStar'} flag={true}/> */}
        </>


        {/* 21st of December - The Romanian Revolution */}
        <>   
            {/* Overlapping image with transparency */}
            {/* <div className="overlay-image-post-revolution-flag"></div>    
            <div className="overlay-image-pre-revolution-flag"></div>    
            <Player image={helicopter} movementType={'floating-helicopter'} flag={true}/>
            <Player image={ak47} movementType={'still-ak47'} flag={true}/>
            <Player image={bullet} movementType={'bullet-move'} flag={true}/>
            <Player image={radium} movementType={'radium-spinning'} flag={true}/> */}
        </>


        {/* 25th of December - Christmas */}
        <>   
            {/* <AnimatedSvgImage image={santa01} cssAndAnimation={'floating-santa'} opacityParam={'0'}/>
            <AnimatedSvgImage image={shootingStar} cssAndAnimation={'shooting-star'} opacityParam={'0'}/> */}
        </>

        {/* 31st of October - The Haloween  */}
        <>   
            {/* <Player image={ghostImage01} movementType={'floating-ghost'}/>
            <Player image={ghostImage07} movementType={'floating-ghost-02'}/>
            <Player image={ghostImage03} movementType={'floating-ghost-03'}/>
            <Player image={ghostImage04} movementType={'floating-ghost-04'}/>
            <Player image={ghostImage05} movementType={'floating-ghost-05'}/>
            <Player image={ghostImage06} movementType={'floating-ghost-06'}/>
            <Player image={ghostImage02} movementType={'floating-ghost-07'}/>
            <Player image={ghostImage08} movementType={'floating-ghost-08'}/>
            <Player image={ghostImage09} movementType={'floating-ghost-09'}/>
            <Player image={ghostImage10} movementType={'floating-ghost-10'}/>
            <Player image={pumpkinImage} movementType={'floating-pumpkin'}/> */}
        </>

        {/* 1st of December - The National Day */}
        <>   
        <Player image={drapel01} movementType={'floating-ghost'} flag={true}/>
        <Player image={drapel07} movementType={'floating-ghost-02'} flag={true}/>
        <Player image={drapel03} movementType={'floating-ghost-03'} flag={true}/>
        <Player image={drapel04} movementType={'floating-ghost-04'} flag={true}/>
        <Player image={drapel05} movementType={'floating-ghost-05'} flag={true}/>
        <Player image={drapel06} movementType={'floating-ghost-06'} flag={true}/>
        <Player image={drapel02} movementType={'floating-ghost-07'} flag={true}/>
        <Player image={drapel08} movementType={'floating-ghost-08'} flag={true}/>
        <Player image={drapel09} movementType={'floating-ghost-09'} flag={true}/>
        <Player image={drapel10} movementType={'floating-ghost-10'}/>
        <Player image={drapel11} movementType={'floating-ghost-11'}/>
        <Player image={coroana} movementType={'floating-pumpkin'}/>
        </>
        </div>
        
    )
}

export default Halloween