import axios from 'axios'

class CartService {

    constructor() {
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL + '/cart',
            withCredentials: true
        })
    }

    
    // getCartTotal1 = ( cart ) => this.app.post('/get-cart-total-1', { cart })
    getCartTotal = ( cartItems ) => this.app.post('/get-cart-total', { cartItems })
}

export default CartService