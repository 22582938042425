import React from "react";
import { Component } from 'react';
import { ListGroup } from 'react-bootstrap'; 
import ExamsService from "../../../services/exams.services";
import './ExamsPage.css'
import ExamEditForm from "./ExamEditForm";

import classes from '../LecturePage/LecturePage.module.css';
import EditLecture from '../SectionPage/edit-svgrepo-com.svg'
import DeleteExam from '../SectionPage/delete-file.svg'


class ExamTestUnit extends Component{
    constructor(){
        super()
        this.state = {
            exam: undefined,
            editExamFormIsShown: false,
            // subject: null,
        }
        this.examsService = new ExamsService()
    }

    toggleEditExamForm = () => {
        this.setState({
            editExamFormIsShown: !this.state.editExamFormIsShown
        })
    }

    handleDeleteExam = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this exam?");
        if (confirmDelete) {
            try {
                await this.examsService.deleteExam(this.props.exam._id);
                // Logic for removing the exam from the list or updating the UI
                alert("Exam deleted successfully");
                // If you are passing this as a prop, you might want to call a method to remove it from the list.
                this.props.onDeleteExam(this.props.exam._id);
            } catch (error) {
                alert("Failed to delete exam");
                console.error(error);
            }
        }
    }

    render(){
        return(
            <>
                <style>
                    {`
                        li.list_style_none {
                            font-size: 0.9rem;
                        }
                    `}
                </style>
    
                <ListGroup.Item className="list_style_none">
                    <a href={this.props.exam?.itemsUrl?.ro ||  this.props.exam.exercisesUrl} rel="noopener noreferrer" target="_blank" className="ExamTestUnit-anchor">{this.props.exam.name} </a> - 
                    <a style={{ color: 'orange', fontSize: '0.8rem' }} href={this.props.exam?.answerKeyUrl?.ro ||  this.props.exam.markingSchemeUrl}

                    // <a style={{ color: 'orange', fontSize: '0.8rem' }} href={this.props.exam.markingSchemeUrl || this.props.exam.answerKeyUrl['ro']} 
                        rel="noopener noreferrer" target="_blank" className="ExamTestUnit-anchor"> barem </a>
                    {                    
                    this.props.exam.videoSolutionUrl
                    ?
                    <span>
                     - 
                    <a style={{ color: this.props.exam.videoSolutionUrl ? 'red' : 'grey' , fontSize: '0.8rem' }} 
                        href={this.props.exam.videoSolutionUrl} rel="noopener noreferrer" target="_blank" className="ExamTestUnit-anchor"> video</a>
                    </span>
                    : 
                    null
                    }

                    { 
                        this.props?.loggedUserRole !== 'admin'
                        ?
                        null
                        :                   
                        <div className={classes.block} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>            
                            <div className={classes.smallPicContainer} onClick={this.toggleEditExamForm}>
                                <img className={classes.smallPic} src={EditLecture} alt="edit lecture"/>
                            </div>
                            <div className={classes.smallPicContainer} onClick={this.handleDeleteExam}>
                                <img className={classes.smallPic} src={DeleteExam} alt="delete exam"/>
                            </div>
                        </div>
                    }

                </ListGroup.Item>                
                

                {this.state.editExamFormIsShown && 
                    <ExamEditForm exam={this.props.exam} toggleEditExamForm = { this.toggleEditExamForm } />
                }

            </>
        )
    }
}

export default ExamTestUnit;
