import { Component } from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
// import isValidEmail from '../../../utils/isValidEmail'
import {isValidEmail} from './../../../utils/utilitaryFunctions'
// import { Link } from 'react-router-dom'

import AuthService from '../../../services/auth.services'
import EmailService from '../../../services/email.services'

class ContactForm extends Component {

    constructor() {
        super()
        this.state = {
            email: '',
            subject: '',
            message: '',
            charCount: 0,
        }
        this.authService = new AuthService()
        this.emailService = new EmailService()
    }


    prefillFields = () => {

        const subject = this.props.match.params.subject
        const email = '' || this.props.match.params.email

        this.setState({email, subject})

    }

    componentDidMount = () => {
        this.prefillFields()
    }

    handleInputChange = e => {
        const { name, value } = e.target
        if(name==='message'){
            this.setState({ [name]: value, charCount: value.length })
        } else{
            this.setState({ [name]: value })
        }
    }


    handleFormSubmit = e => {

        e.preventDefault()

        const { email, subject, message} = this.state
        const { preSubject } = this.props.match.params

        // console.log(`####################### ${preSubject}`)

        if(! (email && subject && message ) ){
            this.props.handleAlert([`Toate câmpurile sunt obligatorii.`], 10000, 'warning', true)
            return
        }else 
        if(! (isValidEmail(email) ) ){
            this.props.handleAlert([`Emailul trebuie să aibă un format valid.`], 10000, 'warning', true)
            return
        }

        // de verificat token-ul
        this.authService
            .generateToken(email)
            .then(res => {
                const {token} = res.data
                this.emailService
                    .sendContactEmail( email, token, subject, message, preSubject)
                    .then(() => {
                        this.props.history.push('/Courses')         // Redirect with RRD props
                        this.props.handleAlert([`Mesajul a fost trimis.`, `Îți vom răspunde în cel mai scurt timp posibil la adresa ${email}.`], 10000, 'info', true)
                    })
                    .catch(err => {                   
                        console.log(err)
                        this.props.handleAlert(err.response?.data.message ? 
                                err.response?.data.message : 
                                ['Mesajul nu a fost trimis din cauza unei erori de rețea.'], 10000, 'warning', true)
                        // TTIA: redirect here.
                    })
                })
                .catch( err => {
                    this.props.handleAlert(err.response?.data.message ? 
                        err.response?.data.message : [err.response?.statusText], 10000, 'warning', true)
                })    

    }



    render() {
        return (

            <Container>

                <Row>

                    <Col md={{ span: 4, offset: 4 }}>

                        <h1>Contact</h1>

{/* Mesaj "server de mail in mentenanta" */}
                        {/* <hr></hr>
                        <div style={{ border: '1px solid #ddd', padding: '20px', backgroundColor: '#f9f9f9' }}>
                            <h2>Mesaj de Mentenanță</h2>
                            <p>Stimate utilizator,</p>
                            <p>Din cauza unei avarii tehnice, serverul nostru de email nu funcționează momentan. Lucrăm intens pentru a rezolva problema și estimăm că serviciul va fi restabilit până <strong>miercuri, 8 ianuarie 2025</strong>.</p>
                            <p>Pentru urgențe sau alte întrebări, te rugăm să ne contactezi prin următoarele mijloace:</p>
                            <ul>
                                <li><strong>Email</strong>: <a href="mailto:matecumatei@gmail.com">matecumatei@gmail.com</a></li>
                                <li><strong>Telefon</strong>: <a href="tel:+40736183176">0736 183 176</a></li>
                            </ul>
                            <p>Îți mulțumim pentru înțelegere și ne cerem scuze pentru eventualele inconveniente cauzate.</p>
                            <p><strong>Echipa MatecuMatei</strong></p>
                        </div>
                        <hr></hr> */}

{/* Mesaj "Server de mail functional" */}
                        {/* <hr></hr>
                        <div style={{ border: '1px solid #ddd', padding: '20px', backgroundColor: '#f9f9f9' }}>
                            <h2>Mesaj de Mentenanță Actualizat</h2>
                            <p>Stimate utilizator,</p>
                            <p>Suntem bucuroși să te anunțăm că serverul nostru de email este acum funcțional! Dacă întâmpini orice problemă sau ai nevoie de asistență suplimentară, nu ezita să ne contactezi.</p>
                            <p>Pentru urgențe sau alte întrebări, te rugăm să ne contactezi prin următoarele mijloace:</p>
                            <ul>
                                <li><strong>Email</strong>: <a href="mailto:matecumatei@gmail.com">matecumatei@gmail.com</a></li>
                                <li><strong>Telefon</strong>: <a href="tel:+40736183176">0736 183 176</a></li>
                            </ul>
                            <p>Îți mulțumim pentru înțelegere și pentru răbdarea acordată!</p>
                            <p><strong>Echipa MatecuMatei</strong></p>
                        </div>
                        <hr></hr> */}

                        <Form onSubmit={this.handleFormSubmit}>

                            <Form.Group controlId="email">
                                <Form.Label>Adresa ta de email:</Form.Label>
                                <Form.Control type="text" value={this.state.email} onChange={this.handleInputChange} name="email" />
                            </Form.Group>

                            <Form.Group controlId="email">
                                <Form.Label>Titlu:</Form.Label>
                                <Form.Control type="text" value={this.state.subject} onChange={this.handleInputChange} name="subject" />
                            </Form.Group>

                            <Form.Group controlId="message">
                                <Form.Label>Mesaj:</Form.Label>
                                <Form.Control as="textarea" rows={11}  value={this.state.message} onChange={this.handleInputChange} name="message" />
                                <small
                                    style={{color: this.state.charCount > 400 ? 'red' : '#6c757d'}}>
                                        Caractere: {this.state.charCount}/400
                                </small>
                            </Form.Group>

                            <Button style={{ marginTop: '20px', width: '100%' }} variant="dark" type="submit" disabled={this.state.charCount > 400}>
                                Trimite
                            </Button>

                        </Form>

                    </Col>
                </Row>

            </Container >

        )
    }
}


export default ContactForm