import axios from 'axios' 
 
 class ExamsService{

    constructor(){
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL + '/exams',
            // baseURL: 'https://matecumatei-server.herokuapp.com/api/courses',
            // baseURL: 'http://localhost:5000/api/courses',
            withCredentials: true
        })
    }

    // getAllExams = (data) => this.app.get('/', {data})
    
    // getAllExams = ( year, typeOfExam, major) => this.app.get(`/?typeOfExam=${typeOfExam}&year=${year}&major=${major}`)
    getAllExamsByYearTypeSubject = ( year, typeOfExam, subject) => this.app.get(`/examsByYearTypeSubjectMajor/?typeOfExam=${typeOfExam}&year=${year}&subject=${subject}`)
    // getYears = (typeOfExam, major) => this.app.get(`/years/?typeOfExam=${typeOfExam}&major=${major}`)
    getYearsByTypeSubject = (typeOfExam, subject) => this.app.get(`/yearsByTypeSubjectMajor/?typeOfExam=${typeOfExam}&subject=${subject}`)
    saveExam = (exam_info) => this.app.post('/new', exam_info)
    editExam = (exam_info) => this.app.put(`/edit/${exam_info._id}`, exam_info)
    deleteExam = (examId) => this.app.delete(`/delete/${examId}`);
 }

 export default ExamsService